import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {checkRedirect, initSdk, invoke, asyncCall, call, SignRes} from 'wecom-sidebar-jssdk';


function App() {
const  [res, setResp] = React.useState('init');
const  [user_id, setUserId] = React.useState('init');
const  [chatId, setChatId] = React.useState('init');
const  [error, setError] = React.useState('');

React.useEffect(() => {
    // (async function init() {
    //     const res1 = await invoke('getCurExternalContact');
    //     setUserId(res1.userId);
    // })()
}, [])

 const handleClick = async () => {
       const res1 = await invoke('getCurExternalContact');
        setUserId(res1.userId);
 }
 
  const handleSend = async () => {
      try{
         const res1 = await asyncCall('openEnterpriseChat', {
            // userIds: ['GaoShanYangZhi'].join(';'),
            groupName:"",
            externalUserIds: 'wmE3gOTAAAZ_XUkFjTKH5dMhhC_XwCxg',
       });  
      }catch(e){
          setError(e.toString())
      }
      
       
 }
 const selectExternalContact = async () => {
     await invoke('selectExternalContact', {"filterType": 0});
 }
 const sendBatch = async () => {
   const a = await invoke('shareToExternalContact', {
      externalUserIds: ["wmE3gOTAAAnw9rgfbF6UgIrtqZI2JiMQ"],
      text: {
            content:"客户端群发测试1",    // 文本内容
      },
      attachments: [
	        {
            	  msgtype: "image",    // 消息类型，必填
				  image: {
						mediaid: "30D9OrsxZ02tAoIP7HxGUuq0HSLFu6NFy8q_UyMc3vVcul8rSXj5BfQIwclFQzkTWXaZ0VVEboG-LokLAfceWNA",      // 图片的素材id
				  },
			}
	    ]
    });
setResp(JSON.stringify(a))
 }
const  handleGetGroupId = async () => {
   const res = await invoke('getCurExternalChat')
     if(res.err_msg == "getCurExternalChat:ok"){
            setChatId(res.chatId) //返回当前外部群的群聊ID
        }else {
            //错误处理
        }
}
const handleCreatChat = async () => {
    try{
         const res1 = await asyncCall('openEnterpriseChat', {
            userIds: ['Lijun'].join(';'),
            groupName:`测试创建外部群${Date.now()}`,
            externalUserIds: ['wmE3gOTAAAnw9rgfbF6UgIrtqZI2JiMQ'].join(';'),
       });  
      }catch(e){
          setError(e.toString())
      }
}
const handleShare = async () => {
    await invoke('shareAppMessage',{
                title: '12312', // 分享标题
                desc: '123', // 分享描述
                link: 'https://baidu.com', // 分享链接
                imgUrl: '', // 分享封面
                enableIdTrans: 1, // 是否开启id转译，不填默认为0
    })
}
 
  return (
      <>
    <div className="App">
        {user_id}
    </div>
     <div className="App2">
        {error}
    </div>
     <div className="App2">
        {chatId}
    </div>
     <div className="App2">
        {res}
    </div>
     <button onClick={handleShare}>分享</button>
    <button onClick={handleClick}>获取外部联系人userId</button>
    <button onClick={handleGetGroupId}>获取群id</button>
    <button onClick={handleSend}>打开会话2</button>
    <button onClick={handleCreatChat}>创建多人会话</button>
    <button onClick={selectExternalContact}>selectExternalContact</button>
    <button onClick={sendBatch}>消息群发</button>
    <div style={{position: 'fixed', bottom: 0, textAlign: 'center', width: '100%'}}>
    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">渝ICP备16010174号-2</a>
    </div>
    </>
  );
}

export default App;
